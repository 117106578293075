<template lang="html">
  <v-container>
    <base-material-card icon="mdi-bank">
      <v-toolbar flat>
        <v-select label="bulan" :items="months" hide-details class="mr-5"></v-select>
        <v-text-field label="Cari" append-icon="mdi-magnify" hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="green" dark><v-icon>mdi-file-excel</v-icon> Export Excel</v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="5"
        hide-default-header
        class="table-ranking"
      >
       <template v-slot:header>
         <thead>
           <tr>
             <th rowspan="3" width="200px">Nama SKPD</th>
             <th rowspan="3">Jumlah Pegawai</th>
             <th rowspan="3">Rata-Rata Menit Utama</th>
             <th rowspan="3">Rata-Rata Menit Tambahan</th>
             <th rowspan="3">Rata-Rata Capaian Aktivitas</th>
             <th rowspan="3">Rata-Rata Perilaku</th>
             <th rowspan="3">Rata-Rata Presensi</th>
             <th rowspan="3">Rata-Rata Kinerja</th>
             <th colspan="8">Kinerja</th>
           </tr>
           <tr>
             <th colspan="2">&lt;50%</th>
             <th colspan="2">50%&lt;x&lt;75</th>
             <th colspan="2">&lt;75%</th>
             <th colspan="2">100%</th>
           </tr>
           <tr>
             <th>Jumlah</th>
             <th>Prosentase</th>
             <th>Jumlah</th>
             <th>Prosentase</th>
             <th>Jumlah</th>
             <th>Prosentase</th>
             <th>Jumlah</th>
             <th>Prosentase</th>
           </tr>
         </thead>
       </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
export default {
  data(){
    return {
      headers:[
        {value:'nama_skpd'},
        {value:'jml_pegawai'},
        {value:'rata2_menit_utama'},
        {value:'rata2_menit_tmb'},
        {value:'rata2_cp_aktivitas'},
        {value:'rata2_cp_perilaku'},
        {value:'rata2_cp_presensi'},
        {value:'rata2_cp_kinerja'},
        {value:'k1_jumlah'},
        {value:'k1_presentase'},
        {value:'k2_jumlah'},
        {value:'k2_presentase'},
        {value:'k3_jumlah'},
        {value:'k3_presentase'},
        {value:'k4_jumlah'},
        {value:'k4_presentase'},
      ],
      months:['Januari', 'Febuari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
      items:[
        { nama_skpd:'Asisten Administrasi Umum dan Kepegawaian',
          jml_pegawai:1,
          rata2_menit_utama:9988,
          rata2_menit_tmb:0,
          rata2_cp_aktivitas:100,
          rata2_cp_perilaku:99,
          rata2_cp_presensi:100,
          rata2_cp_kinerja:99,
          k1_jumlah:0,
          k1_presentase:"0%",
          k2_jumlah:0,
          k2_presentase:"0%",
          k3_jumlah:1,
          k3_presentase:"100%",
          k4_jumlah:0,
          k4_presentase:"0%"},
        { nama_skpd:'Asisten Administrasi Umum dan Kepegawaian',
          jml_pegawai:1,
          rata2_menit_utama:9988,
          rata2_menit_tmb:0,
          rata2_cp_aktivitas:100,
          rata2_cp_perilaku:99,
          rata2_cp_presensi:100,
          rata2_cp_kinerja:99,
          k1_jumlah:0,
          k1_presentase:"0%",
          k2_jumlah:0,
          k2_presentase:"0%",
          k3_jumlah:1,
          k3_presentase:"100%",
          k4_jumlah:0,
          k4_presentase:"0%"}
      ]
    }
  }
}
</script>

<style lang="css" scoped>
.table-ranking tr,td,th{
  border:1px solid #eee;
}
</style>
